<div *ngIf="!showImprovedUX" class="locations-list reused-component">
  Locations
  <a
    role="button"
    tabindex="0"
    (keydown.enter)="handleMoreLess()"
    (click)="handleMoreLess()"
    class="more-locations"
    *ngIf="officeAddresses.length > 1"
  >
    {{ hideExtraContent ? 'View more' : 'View less' }}
    <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 6L11 1" />
    </svg>
  </a>
  <div *ngFor="let office of officeAddresses.slice(0, 1); let i = index" class="office">
    <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
  </div>
  <div *ngIf="!hideExtraContent && officeAddresses.length > 1">
    <div *ngFor="let office of officeAddresses.slice(1); let i = index" class="office">
      <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
    </div>
  </div>
</div>

<div *ngIf="showImprovedUX" class="locations-list-new reused-component">
  <div class="detials-sections">
    <div class="location-detail">
      <div class="location-header">
        <div>Locations</div>
        <div class="view-more-container-mobile" *ngIf="officeAddresses.length > 1 && resizeService.isMobileViewport">
          <a role="button" tabindex="0" (keydown.enter)="handleMoreLess()" (click)="handleMoreLess()" class="more-locations">
            {{ hideExtraContent ? 'View more' : 'View less' }}
            <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6L11 1" />
            </svg>
          </a>
        </div>
      </div>

      <ng-container *ngIf="resizeService.isDesktopViewport">
        <div *ngFor="let office of officeAddresses.slice(0, 1); let i = index" class="office">
          <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
        </div>
        <div *ngIf="!hideExtraContent && officeAddresses.length > 1">
          <div *ngFor="let office of officeAddresses.slice(1); let i = index" class="office">
            <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!resizeService.isDesktopViewport">
        <div *ngFor="let office of officeAddresses.slice(0, 1); let i = index" class="office">
          <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
        </div>
        <div *ngIf="!hideExtraContent && officeAddresses.length > 1">
          <div *ngFor="let office of officeAddresses.slice(1); let i = index" class="office">
            <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="phone-detail" *ngIf="resizeService.isDesktopViewport">
      Phone
      <div *ngFor="let office of officeAddresses.slice(0, 1); let i = index" class="office">
        <ng-container *ngTemplateOutlet="phoneDetails; context: { office: office, i: i }"></ng-container>
      </div>
      <div *ngIf="!hideExtraContent && officeAddresses.length > 1">
        <div *ngFor="let office of officeAddresses.slice(1); let i = index" class="office">
          <ng-container *ngTemplateOutlet="phoneDetails; context: { office: office, i: i }"></ng-container>
        </div>
      </div>
    </div>
    <div class="direction-detail" *ngIf="resizeService.isDesktopViewport">
      Directions
      <div *ngFor="let office of officeAddresses.slice(0, 1); let i = index" class="office">
        <ng-container *ngTemplateOutlet="directionDetails; context: { office: office, i: i }"></ng-container>
      </div>
      <div *ngIf="!hideExtraContent && officeAddresses.length > 1">
        <div *ngFor="let office of officeAddresses.slice(1); let i = index" class="office">
          <ng-container *ngTemplateOutlet="directionDetails; context: { office: office, i: i }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="view-more-container" *ngIf="officeAddresses.length > 1 && !resizeService.isMobileViewport">
    <a role="button" tabindex="0" (keydown.enter)="handleMoreLess()" (click)="handleMoreLess()" class="more-locations">
      {{ hideExtraContent ? 'View more' : 'View less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6L11 1" />
      </svg>
    </a>
  </div>
</div>

<ng-template #officeDetails let-office="office" let-i="i">
  <div class="rank-icon">
    <svg viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36976 15.6722C5.65073 16.1093 6.30634 16.1093 6.58732 15.6722C11.1454 9.1161 11.9883 8.42927 11.9883 5.99415C11.9883 2.68488 9.30342 0 5.99415 0C2.65366 0 0 2.68488 0 5.99415C0 8.42927 0.811707 9.1161 5.36976 15.6722Z"
      />
    </svg>
  </div>
  <div class="office-details">
    <div class="office-address">
      <div class="office-name">
        <p *ngIf="!office.name" class="p p2">{{ provider.officeNames[i] }}</p>
        <p *ngIf="office.name && (office.name.length == 1 ? !office.name.includes('.') : true)" class="p p2">{{ office.name }}</p>
      </div>
      <div class="street">
        <p *ngIf="resizeService.isMobileViewport" class="p3">
          {{ office.address }}<br />
          {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}
        </p>
        <p *ngIf="!resizeService.isMobileViewport && isProviderCardContent && office.phones?.length" class="p2">
          {{ office.address }}<span *ngIf="office.address">, </span> {{ office.city }}<span *ngIf="office.city">, </span> {{ office.state }}
          {{ office.zip }}
        </p>
        <p *ngIf="!resizeService.isMobileViewport && isProviderCardContent && !office.phones?.length" class="p2">
          {{ office.address }}<br />
          {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}
        </p>
        <p *ngIf="!resizeService.isMobileViewport && !isProviderCardContent && office.phones?.length" class="p2">
          {{ office.address }}<span *ngIf="office.address">, </span> {{ office.city }}<span *ngIf="office.city">, </span> {{ office.state }}
          {{ office.zip }}
        </p>
        <p *ngIf="!resizeService.isMobileViewport && !isProviderCardContent && !office.phones?.length" class="p2">
          {{ office.address }}<br />
          {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}
        </p>
      </div>
      <div class="contact-number phone" *ngIf="office.phones && (!showImprovedUX || !resizeService.isDesktopViewport)">
        <p *ngIf="!resizeService.isDesktopViewport" class="p3">
          <a href="tel:{{ office.phones }}">{{ office.phones }}</a>
        </p>
        <p *ngIf="resizeService.isDesktopViewport" class="p2">{{ office.phones }}</p>
      </div>
    </div>
    <div class="right-info" *ngIf="!showImprovedUX || !resizeService.isDesktopViewport">
      <div>
        <div *ngIf="!this.isProviderCardContent" class="proximity-display">{{ office.distanceMilesFromSearch | formatDistance }}</div>
      </div>
      <div class="get-directions">
        <a
          *ngIf="!office.name"
          [attr.aria-label]="getDirectionText(provider.officeNames[i])"
          href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
          target="_blank"
          >Get directions</a
        >
        <a
          *ngIf="office.name && (office.name.length == 1 ? !office.name.includes('.') : true)"
          [attr.aria-label]="getDirectionText(office.name)"
          href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
          target="_blank"
          >Get directions</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #phoneDetails let-office="office" let-i="i">
  <div class="phone-details">
    <div class="contact-number phone" *ngIf="office.phones">
      <p *ngIf="!resizeService.isDesktopViewport" class="p3">
        <a href="tel:{{ office.phones }}">{{ office.phones }}</a>
      </p>
      <p *ngIf="resizeService.isDesktopViewport" class="p2">{{ office.phones }}</p>
    </div>
  </div>
</ng-template>
<ng-template #directionDetails let-office="office" let-i="i">
  <div class="direction-details">
    <div *ngIf="!this.isProviderCardContent" class="proximity-display">{{ office.distanceMilesFromSearch | formatDistance }}</div>
    <div class="get-directions">
      <a
        *ngIf="!office.name"
        [attr.aria-label]="getDirectionText(provider.officeNames[i])"
        href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
        target="_blank"
        >Get directions</a
      >
      <a
        *ngIf="office.name && (office.name.length == 1 ? !office.name.includes('.') : true)"
        [attr.aria-label]="getDirectionText(office.name)"
        href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
        target="_blank"
        >Get directions</a
      >
    </div>
  </div>
</ng-template>
