import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { SessionObjectNames } from 'src/app/models/stringconst-names';
import { OfficeAddress } from '../../models/office-address';
import {
  KeywordSuggestion,
  KeywordSuggestionResponse,
  Provider,
  ProviderInterfaceHelper,
  ProviderSuggestionResponse,
  ProviderSuggestionV2Response,
  ProviderTextV2
} from '../../models/provider';
import { ProviderOffice } from '../../models/provider-office';
import { SearchSuggestions } from '../../models/search-suggestions';
import { ConfigurationService } from '../../services/configuration.service';
import { ProviderService } from '../../services/provider.service';
import { ResizeService, ViewPortSize } from '../../services/resize.service';
import { ApiResponse } from '../../utils/api-response';
import './../../utils/string-prototypes';
import { Router } from '@angular/router';

@Component({
  selector: 'cs-provider-search',
  templateUrl: './provider-search.component.html',
  styleUrls: ['./provider-search.component.scss']
})
export class ProviderSearchComponent implements OnInit, OnDestroy {
  @Input() searchTerm: string;
  @Input() hideProviderSuggestions: boolean;
  @Input() autoSuggestionLoadingMessage: string;
  @Output() searchTermChanged = new EventEmitter<string>();
  @Output() keywordEnter = new EventEmitter<string>();
  @Output() goToProviderEvent = new EventEmitter<any>();
  @Output() specialtyChanged = new EventEmitter<string>();
  @Output() tabPressedOnProviderSearch = new EventEmitter<any>();

  suggestions: SearchSuggestions = {
    providers: [],
    keywords: [],
    specialties: []
  };
  autocompleteActive = false;
  providerSliceIndex = 3;
  keywordSliceIndex = 3;
  specialtySliceIndex = 3;
  searchPlaceholder: string;
  showSpecialtySuggestions: boolean;
  activeProviderDropdownIndex = -1;
  activeKeywordDropdownIndex = -1;
  activeSpecialtyDropdownIndex = -1;
  defaultSliceIndex = 2;
  lastFocusWasFromKeyboard = false;
  seeLessMoreClicked = false;
  seeLessMoreFocused = false;
  isFromexpandLessCick = false;
  enableAutoSuggestionProviders: boolean;
  providerCount = 0;
  useLegacyAPI = false;
  viewPort$: Subscription;
  results$: Subscription;
  subject = new ReplaySubject<string>(1);
  showLoadingMessage = false;
  inputInFocus = false;
  changeBtnStyle:string;
  private readonly inputDebounceMS = 300;
  private readonly noSuggestionsSelected = -1;
  private inputModelChanged: Subject<string> = new Subject<string>();

  @ViewChild('providerSuggestionsContainer') providerSuggestionsContainer: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChild('specialitySeeMore') specialitySeeMore: ElementRef;
  @ViewChild('providerSeeMore') providerSeeMore: ElementRef;
  @ViewChild('keywordSeeMore') keywordSeeMore: ElementRef;
  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.providerSuggestionsContainer.nativeElement.contains(event.target)) {
      this.autocompleteActive = false;
      this.providerSliceIndex = 3;
      this.keywordSliceIndex = 3;
      this.specialtySliceIndex = 3;
      this.activeProviderDropdownIndex = this.noSuggestionsSelected;
      this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
    } else if (
      this.searchTerm &&
      this.searchTerm !== ' ' &&
      (this.suggestions.providers.length > 0 || this.suggestions.keywords.length > 0 || this.suggestions.specialties.length > 0)
    ) {
      this.autocompleteActive = true;
    }
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove() {
    this.lastFocusWasFromKeyboard = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(keyEvent: KeyboardEvent) {
    const keyCode = keyEvent.key.toLowerCase();
    if (keyCode === 'arrowdown' || keyCode === 'arrowup') {
      if (this.seeLessMoreClicked) {
        if (this.seeLessMoreFocused) {
          this.seeLessMoreFocused = false;
          this.searchElement.nativeElement.focus();
          this.keyDownHandler(keyEvent);
        } else {
          this.seeLessMoreFocused = true;
        }
      }
    }
    if (keyCode === 'tab' && this.autocompleteActive) {
      this.tabPressedOnProviderSearch.emit();
    }
  }

  constructor(
    private providerService: ProviderService,
    private resizeService: ResizeService,
    private configurationService: ConfigurationService,
    private router: Router,
  ) {
    this.useLegacyAPI = this.configurationService.useLegacyAPI();
  }

  ngOnDestroy(): void {
    this.results$.unsubscribe();
    this.subject.unsubscribe();
    this.viewPort$.unsubscribe();
  }

  ngOnInit(): void {
    this.viewPort$ = this.resizeService.viewPortChanged.pipe(tap((result: ViewPortSize) => this.setPlaceholderText(result))).subscribe();


    this.results$ = this.subject
      .pipe(
        filter((searchText) => !!searchText && searchText.length > 0),
        switchMap((searchTerm) => {
          if (!this.useLegacyAPI) {
            return this.getSearchSuggestionsV2(searchTerm).pipe(
              catchError(() => {
                return of(undefined);
              })
            );
          } else {
            return this.getSearchSuggestions(searchTerm).pipe(
              catchError(() => {
                return of(undefined);
              })
            );
          }
        }),
        tap(() => {
          this.showLoadingMessage = false;
          if (!this.inputInFocus) {
            this.setAutocompleteActive(false);
            return;
          }
          this.setAutocompleteActive(
            this.suggestions.providers.length > 0 || this.suggestions.keywords.length > 0 || this.suggestions.specialties.length > 0
          );
        })
      )
      .subscribe();

    this.enableAutoSuggestionProviders = this.configurationService.shouldEnableAutoSuggestionProviders();
    this.inputModelChanged.pipe(debounceTime(this.inputDebounceMS)).subscribe((event) => this.onChangeSearch(event));
    this.showSpecialtySuggestions = this.configurationService.showSpecialtySuggestions();
    this.changeBtnStyle= this.configurationService.getSearchButton();
  }

  setPlaceholderText(viewportSize: ViewPortSize) {
    if (viewportSize === ViewPortSize.Desktop) {
      this.searchPlaceholder = this.configurationService.getSearchTermDeskTop()
    } else {
      this.searchPlaceholder = viewportSize === ViewPortSize.Tablet
      ? this.configurationService.getSearchTermTablet()
      : this.configurationService.getSearchTermMobile();
    }
  }

  getSearchSuggestions(
    query: string
  ): Observable<
    [ApiResponse<ProviderSuggestionResponse>, ApiResponse<KeywordSuggestionResponse>, ApiResponse<ProviderSuggestionResponse>]
  > {
    return forkJoin([
      this.getProviderSuggestionsTyped(query),
      this.getKeywordSuggestionsTyped(query),
      this.getSpecialtySuggestionsTyped(query)
    ]);
  }

  getSearchSuggestionsV2(
    query: string
  ): Observable<
    [ApiResponse<ProviderSuggestionResponse>, ApiResponse<KeywordSuggestionResponse>, ApiResponse<ProviderSuggestionV2Response>]
  > {
    return forkJoin([
      this.getProviderSuggestionsTyped(query),
      this.getKeywordSuggestionsTyped(query),
      this.getSpecialtyV2SuggestionsTyped(query)
    ]);
  }

  getProviderSuggestionsTyped(query: string): Observable<ApiResponse<ProviderSuggestionResponse>> {
    return this.providerService.getTypedProviderSuggestions(query, 'provider').pipe(
      tap((response) => {
        let providers: Provider[] = [];
        if (response.isValid) {
          this.providerCount = response.result.providerCount;
          providers = response.result.items
            .map((s) => s.providers)
            .reduce((a: Provider[], b: Provider[]) => a.concat(b.filter(this.onlyUnique)), []);
        }
        providers.forEach((provider: Provider) => {
          this.updateProviderFullName(provider);
        });
        this.suggestions.providers = providers;
      })
    );
  }

  updateProviderFullName(provider: Provider): void {
    if (!this.configurationService.showProviderPrefix()) {
      provider.displayFullName = ProviderInterfaceHelper.removeProviderNamePrefix(provider.firstName, provider.lastName, provider.degree);
    }
  }

  private onlyUnique<T>(value: T, index, array: T[]): boolean {
    return array.indexOf(value) === index;
  }

  getKeywordSuggestionsTyped(query: string): Observable<ApiResponse<KeywordSuggestionResponse>> {
    return this.providerService.getTypedKeywordSuggestions(query).pipe(
      tap((response) => {
        let keywords: string[] = [];
        if (response.isValid) {
          keywords = response.result.items
            .map((suggestion: KeywordSuggestion) => suggestion.textAndQuery.toTitleCase())
            .filter(this.onlyUnique);
        }
        this.suggestions.keywords = keywords;
      })
    );
  }

  getSpecialtySuggestionsTyped(query: string): Observable<ApiResponse<ProviderSuggestionResponse>> {
    return this.providerService.getTypedProviderSuggestions(query, 'specialty').pipe(
      tap((response) => {
        let specialties: string[] = [];
        if (response.isValid) {
          specialties = response.result.items.map((s) => s.text).filter(this.onlyUnique);
        }
        this.suggestions.specialties = specialties;
      })
    );
  }

  getSpecialtyV2SuggestionsTyped(query: string): Observable<ApiResponse<ProviderSuggestionV2Response>> {
    return this.providerService.getTypedProviderSuggestionsV2(query).pipe(
      tap((response) => {
        let specialityText: ProviderTextV2[] = [];
        const specialities: string[] = [];
        if (response.isValid && response.result.items.length > 0) {
          specialityText = response.result.items
            .map((s) => s.text)
            .reduce((a: ProviderTextV2[], b: ProviderTextV2[]) => (!this.useLegacyAPI ? [...a, ...b] : b.concat([...new Set(a).values()])));
        }
        specialityText.forEach((text: ProviderTextV2) => {
          if (!specialities.includes(text.specialty)) {
            specialities.push(text.specialty);
          }
        });
        this.suggestions.specialties = !this.useLegacyAPI ? [...specialities] : [...new Set(specialities)].sort();
      })
    );
  }

  onInputChange(event) {
    this.showLoadingMessage = true;
    this.setAutocompleteActive(false);
    this.inputModelChanged.next(event);
  }

  onChangeSearch(val: string) {
    this.providerSliceIndex = 3;
    this.keywordSliceIndex = 3;
    this.specialtySliceIndex = 3;
    this.suggestions = {
      providers: [],
      keywords: [],
      specialties: []
    };

    this.searchTermChanged.emit(val);

    val = val.replace(/^[^a-zA-Z0-9&-]+$/, '');
    if (val && val.match(/^ *$/) === null) {
      this.subject.next(val);
      this.searchTermChanged.emit(val);
    } else {
      this.setAutocompleteActive(false);
    }
  }

  setAutocompleteActive(value: boolean) {
    if (value === true) {
      if (this.suggestions.specialties.length > 0) {
        this.activeSpecialtyDropdownIndex = 0;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      } else if (this.suggestions.providers.length > 0) {
        this.activeProviderDropdownIndex = 0;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
      } else if (this.suggestions.keywords.length > 0) {
        this.activeKeywordDropdownIndex = 0;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
      }
    }

    this.autocompleteActive = value;
  }

  keywordClick(keyword: string) {
    this.autocompleteActive = false;
    window.sessionStorage.removeItem(SessionObjectNames.SingleSpecialityText);
    this.searchTerm = keyword;
    this.searchTermChanged.emit(keyword);
    this.searchElement.nativeElement.focus();
  }

  keywordSeeAllProviderSearchClick() {
    this.autocompleteActive = false;
    this.keywordEnter.emit(this.searchTerm);
    this.searchElement.nativeElement.focus();
  }

  specialtyClick(specialty: string) {
    sessionStorage.removeItem('prevSpecialityisAutoTgrChkbx');
    this.autocompleteActive = false;
    window.sessionStorage.setItem(SessionObjectNames.SingleSpecialityText, specialty);
    this.specialtyChanged.emit(specialty);
  }

  goToProvider(npi: string | number, firstName: string, lastName: string, primarySpecialty: string) {
    window.sessionStorage.removeItem(SessionObjectNames.SingleSpecialityText);
    sessionStorage.setItem('searchResultsOrigin', 'true');
    const siteUrl = this.router.url;
    window.sessionStorage.setItem('cernerfadSearchUrl', siteUrl);
    this.goToProviderEvent.emit({ npi, firstName, lastName, primarySpecialty });
  }

  checkforPrimarySpeciality(provider): any {
    let primarySpeciality: any;
    if (!this.useLegacyAPI) {
      primarySpeciality = provider.primarySpecialty[0].specialty;
    } else {
      primarySpeciality = provider.primarySpecialty;
    }
    return primarySpeciality;
  }

  providerHighlight(provider: Provider) {
    let primaryOffice = null;
    provider.offices?.forEach((office: ProviderOffice) => {
      office.addresses.forEach((address: OfficeAddress) => {
        if (address.isPrimaryOffice) {
          primaryOffice = address;
        }
      });
    });
    let highlightString = `${provider.displayFullName}`;
    if (primaryOffice) {
      highlightString += `, ${primaryOffice.city}, ${primaryOffice.state}`;
    } else if (provider.offices && provider.offices.length > 0) {
      const office = provider.offices[0].addresses[0];
      highlightString += `, ${office.city}, ${office.state}`;
    }

    const reg = new RegExp(this.searchTerm, 'ig');
    return highlightString.replace(reg, (match: any) => `<i>${match}</i>`);
  }

  queryHighlight(highlightString: string) {
    const reg = new RegExp(this.searchTerm, 'ig');
    return highlightString.replace(reg, (match: any) => `<i>${match}</i>`);
  }

  handleKeywordChange() {
    if (this.seeLessMoreClicked) {
      this.seeLessMoreClicked = false;
      return;
    }
    this.seeLessMoreClicked = false;
    let specialtyChosen = false;
    document.querySelectorAll('.specialties-list li').forEach((item) => {
      if (!specialtyChosen) {
        specialtyChosen = item.classList.contains('selected');
      }
    });

    let providerChosen = false;
    document.querySelectorAll('.providers-list li').forEach((item) => {
      if (!providerChosen) {
        providerChosen = item.classList.contains('selected');
      }
    });

    let keywordChosen = false;
    document.querySelectorAll('.keywords-list li').forEach((item) => {
      if (!keywordChosen) {
        keywordChosen = item.classList.contains('selected');
      }
    });

    if (providerChosen && this.autocompleteActive) {
      const selectedProvider = this.suggestions.providers[this.activeProviderDropdownIndex];
      this.goToProvider(selectedProvider.npi, selectedProvider.firstName, selectedProvider.lastName, selectedProvider.primarySpecialty);
    } else if (keywordChosen && this.autocompleteActive) {
      const selectedKeyword = this.suggestions.keywords[this.activeKeywordDropdownIndex];
      this.keywordClick(selectedKeyword);
    } else if (specialtyChosen && this.autocompleteActive) {
      const selectedSpecialty = this.suggestions.specialties[this.activeSpecialtyDropdownIndex];
      this.specialtyClick(selectedSpecialty);
    } else {
      this.keywordEnter.emit(this.searchTerm);
    }
    this.autocompleteActive = false;
  }

  onFocusGained(): void {
    this.inputInFocus = true;
  }

  onFocusLost(event: FocusEvent | any) {
    if (
      (event.target && !this.providerSuggestionsContainer.nativeElement.contains(event.target)) ||
      (event.relatedTarget && !this.providerSuggestionsContainer.nativeElement.contains(event.relatedTarget))
    ) {
      this.inputInFocus = false;
    }
  }

  keyDownHandler(keyEvent: KeyboardEvent): void {
    const keyCode = keyEvent.key.toLowerCase();
    if (keyCode === 'arrowdown') {
      keyEvent.preventDefault();
      this.arrowHandler(1);
    } else if (keyCode === 'arrowup') {
      keyEvent.preventDefault();
      this.arrowHandler(-1);
    }
  }

  handleMouseEnter(index: number, type: string): void {
    if (!this.lastFocusWasFromKeyboard) {
      if (this.seeLessMoreClicked) {
        this.seeLessMoreClicked = false;
        this.seeLessMoreFocused = false;
        this.searchElement.nativeElement.focus();
      }
      if (type == 'specialty') {
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = index;
      } else if (type == 'provider') {
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeProviderDropdownIndex = index;
      } else {
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = index;
      }
    }
  }

  arrowHandler(increment: number): void {
    this.lastFocusWasFromKeyboard = true;
    this.seeLessMoreClicked = false;
    if (this.activeSpecialtyDropdownIndex > this.noSuggestionsSelected) {
      if (this.activeSpecialtyDropdownIndex + increment === -1) {
        this.activeSpecialtyDropdownIndex = 0;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      } else if (this.activeSpecialtyDropdownIndex + increment < this.specialtySliceIndex) {
        this.activeSpecialtyDropdownIndex += increment;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      } else if (this.activeSpecialtyDropdownIndex + increment === this.specialtySliceIndex && this.specialitySeeMore) {
        this.activeSpecialtyDropdownIndex += increment;
        this.seeLessMoreClicked = true;
        this.specialitySeeMore.nativeElement.focus();
      } else {
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeProviderDropdownIndex = 0;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      }
    } else if (this.activeProviderDropdownIndex > this.noSuggestionsSelected) {
      if (this.activeProviderDropdownIndex + increment === -1) {
        if (this.specialitySeeMore) {
          this.seeLessMoreClicked = true;
          this.specialitySeeMore.nativeElement.focus();
          this.activeSpecialtyDropdownIndex = this.specialtySliceIndex;
        } else {
          this.activeSpecialtyDropdownIndex = this.specialtySliceIndex - 1;
        }
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
      } else if (this.activeProviderDropdownIndex + increment < this.providerSliceIndex) {
        this.activeProviderDropdownIndex += increment;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      } else if (this.activeProviderDropdownIndex + increment === this.providerSliceIndex && this.providerSeeMore) {
        this.activeProviderDropdownIndex += increment;
        this.seeLessMoreClicked = true;
        this.providerSeeMore.nativeElement.focus();
      } else {
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = 0;
      }
    } else if (this.activeKeywordDropdownIndex > this.noSuggestionsSelected) {
      if (this.activeKeywordDropdownIndex + increment === -1) {
        if (this.providerSeeMore) {
          this.seeLessMoreClicked = true;
          this.providerSeeMore.nativeElement.focus();
          this.activeProviderDropdownIndex = this.providerSliceIndex;
        } else {
          this.activeProviderDropdownIndex = this.providerSliceIndex - 1;
        }
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
      } else if (this.activeKeywordDropdownIndex + increment < this.keywordSliceIndex) {
        this.activeProviderDropdownIndex += this.noSuggestionsSelected;
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeKeywordDropdownIndex += increment;
      } else if (this.activeKeywordDropdownIndex + increment === this.keywordSliceIndex && this.keywordSeeMore) {
        this.activeKeywordDropdownIndex += increment;
        this.seeLessMoreClicked = true;
        this.keywordSeeMore.nativeElement.focus();
      } else {
        this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
        this.activeProviderDropdownIndex = this.noSuggestionsSelected;
        if (this.keywordSeeMore) {
          this.activeKeywordDropdownIndex = this.keywordSliceIndex;
          this.seeLessMoreClicked = true;
          this.keywordSeeMore.nativeElement.focus();
        } else {
          this.activeKeywordDropdownIndex = this.keywordSliceIndex - 1;
        }
      }
    }
  }

  expandLessCick(type) {
    this.seeLessMoreFocused = false;
    this.isFromexpandLessCick = true;
    let controlTabFor = null;
    if (type === 'specialty') {
      this.specialtySliceIndex = this.specialtySliceIndex < this.suggestions?.specialties?.length ? this.suggestions.specialties.length : 3;
      this.activeSpecialtyDropdownIndex = this.defaultSliceIndex;
      if(this.specialtySliceIndex > 3) {
        controlTabFor = 'specialty_3';
      }
    } else if (type === 'provider') {
      this.providerSliceIndex = this.providerSliceIndex < this.suggestions?.providers?.length ? this.suggestions.providers.length : 3;
      this.activeProviderDropdownIndex = this.defaultSliceIndex;
      if(this.providerSliceIndex > 3) {
        controlTabFor = 'provider_3';
      }
    } else {
      this.keywordSliceIndex = this.keywordSliceIndex < this.suggestions?.keywords?.length ? this.suggestions.keywords.length : 3;
      this.activeKeywordDropdownIndex = this.defaultSliceIndex;
      if(this.keywordSliceIndex > 3) {
        controlTabFor = 'keyword_3';
      }
    }
    setTimeout(() => {
      if (controlTabFor != null) {
        document.getElementById(controlTabFor).focus();
      } else {
        this.searchElement.nativeElement.focus();
      }

    }, 0);
  }

  seeLessMoreHover(type) {
    if (this.isFromexpandLessCick) {
      this.isFromexpandLessCick = false;
      return;
    }
    this.seeLessMoreClicked = true;
    this.seeLessMoreFocused = true;

    setTimeout(() => {
      this.lastFocusWasFromKeyboard = true;
    }, 1000);
    if (type === 'specialty') {
      this.activeSpecialtyDropdownIndex = this.specialtySliceIndex;
      this.specialitySeeMore.nativeElement.focus();
      this.activeProviderDropdownIndex = this.noSuggestionsSelected;
      this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
    } else if (type === 'provider') {
      this.activeProviderDropdownIndex = this.providerSliceIndex;
      this.providerSeeMore.nativeElement.focus();
      this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
      this.activeKeywordDropdownIndex = this.noSuggestionsSelected;
    } else {
      this.activeKeywordDropdownIndex = this.keywordSliceIndex;
      this.keywordSeeMore.nativeElement.focus();
      this.activeSpecialtyDropdownIndex = this.noSuggestionsSelected;
      this.activeProviderDropdownIndex = this.noSuggestionsSelected;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.hideProviderSuggestions?.currentValue) {
      this.setAutocompleteActive(false);
    }
  }

  getProviderName(provider: Provider): string {
    return `${provider.firstName} ${provider.lastName}`;
  }
}
