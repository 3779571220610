import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PatientAgeRestrictionsComponent } from './patient-age-restrictions.component';

@NgModule({
  declarations: [PatientAgeRestrictionsComponent],
  imports: [
    CommonModule
  ],
  exports: [PatientAgeRestrictionsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PatientAgeRestrictionsModule {}
