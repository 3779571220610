import { Component, OnInit, Input, OnChanges, ViewChild, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ROOT_ELEMENT } from '../../utils/utils';
import { LogService } from '../../services/log.service';
import { OfficeAddress } from '../../models/office-address';

export class MarkerObject {
  constructor(public options: google.maps.MarkerOptions, public address: OfficeAddress) {}
}

@Component({
  selector: 'cs-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit, OnChanges {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  @Input() officeAddresses: OfficeAddress[] = [];
  @Input() centerPoint: OfficeAddress;

  map: google.maps.Map;
  mapOptions: google.maps.MapOptions;
  markers: MarkerObject[] = [];

  largePin: SVGElement;
  pin: SVGElement;

  showMap = false;
  isMapRendered = false;
  apiLoaded: Observable<boolean>;

  googleMapUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD_NwYjvtIT5M3INWacpNn1T8GzAMBkDq0';

  /**
   * Map position
   * @readonly
   * @type {google.maps.LatLng}
   * @memberof GoogleMapsComponent
   */
  get position(): google.maps.LatLng {
    const lat = this.centerPoint.latitude;
    const long = this.centerPoint.longitude;
    return new google.maps.LatLng(lat, long);
  }

  constructor(private httpClient: HttpClient, private logService: LogService, @Inject(ROOT_ELEMENT) public rootElement: HTMLElement) {}

  ngOnInit(): void {
    this.setupPins();
    this.initGoogleMap();
  }

  ngOnChanges(): void {
    if (this.markers.length > 0) {
      this.updateMap();
    }
  }

  setupPins(): void {
    this.largePin = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.largePin.setAttribute('width', '39');
    this.largePin.setAttribute('height', '51');
    this.largePin.setAttribute('fill', 'none');
    this.largePin.setAttribute('viewBox', '0 0 39 51');
    this.largePin.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const largePinPath = document.createElement('path');
    largePinPath.setAttribute(
      'd',
      'M17.6771 47.7552C18.4974 49.0313 20.4115 49.0313 21.2318 47.7552C34.5391 28.6146 37 26.6094 37 19.5C37 9.83854 29.1615 2 19.5 2C9.7474 2 2 9.83854 2 19.5C2 26.6094 4.36979 28.6146 17.6771 47.7552Z'
    );
    largePinPath.setAttribute('fill', this.rootElement?.style.getPropertyValue('--location-pin-color'));
    largePinPath.setAttribute('stroke', this.rootElement?.style.getPropertyValue('--location-pin-outline'));
    largePinPath.setAttribute('stroke-width', '3');
    this.largePin.appendChild(largePinPath);
    this.pin = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.pin.setAttribute('width', '27');
    this.pin.setAttribute('height', '37');
    this.pin.setAttribute('fill', 'none');
    this.pin.setAttribute('viewBox', '0 0 27 37');
    this.pin.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const pinPath = document.createElement('path');
    pinPath.setAttribute(
      'd',
      'M12.0938 35.7969C12.7266 36.7812 14.2031 36.7812 14.8359 35.7969C25.1016 21.0312 27 19.4844 27 14C27 6.54688 20.9531 0.5 13.5 0.5C5.97656 0.5 0 6.54688 0 14C0 19.4844 1.82812 21.0312 12.0938 35.7969Z'
    );
    pinPath.setAttribute('fill', this.rootElement?.style.getPropertyValue('--location-pin-color'));
    this.pin.appendChild(pinPath);
  }

  initGoogleMap(): void {
    this.apiLoaded = this.httpClient.jsonp(this.googleMapUrl, 'callback').pipe(
      map(() => {
        this.configureMap();
        return true;
      }),
      catchError((err) => {
        this.logService.error(err);
        return of(false);
      })
    );
  }

  configureMap(): void {
    this.mapOptions = {
      center: this.position,
      gestureHandling: 'greedy',
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 10
    };
  }

  renderMap(): void {
    this.officeAddresses.forEach((address: OfficeAddress, index: number) => {
      const labelText = this.officeAddresses.length > 1 ? (this.officeAddresses.indexOf(address) + 1).toString() : null;
      const pin = index === 0 ? this.largePin.outerHTML : this.pin.outerHTML;
      const markerOptions = {
        position: new google.maps.LatLng(address.latitude, address.longitude),
        icon: {
          url: `data:image/svg+xml,${encodeURIComponent(pin)}`,
          labelOrigin: new google.maps.Point(index === 0 ? 20 : 14, index === 0 ? 20 : 15)
        },
        map: this.map,
        label: labelText
          ? {
              text: labelText,
              color: this.rootElement.style.getPropertyValue('--location-pin-label'),
              fontFamily: this.rootElement.style.getPropertyValue('--dhcl-font-family'),
              fontSize: '12px',
              fontWeight: 'normal'
            }
          : null
      };
      this.markers.push({ options: markerOptions, address } as MarkerObject);
    });

    if (this.markers.length > 0) {
      this.markers[0].options.zIndex = 10;
    }
    this.createMap();
  }

  updateMap(): void {
    if (this.centerPoint && this.markers.length > 1) {
      this.markers.forEach((markerObj: MarkerObject) => {
        if (markerObj.address.address === this.centerPoint.address && markerObj.address.id === this.centerPoint.id) {
          markerObj.options.icon = {
            url: `data:image/svg+xml,${encodeURIComponent(this.largePin.outerHTML)}`,
            labelOrigin: new google.maps.Point(20, 20)
          };
          markerObj.options.zIndex = 10;
        } else {
          markerObj.options.icon = {
            url: `data:image/svg+xml,${encodeURIComponent(this.pin.outerHTML)}`,
            labelOrigin: new google.maps.Point(14, 15)
          };
          markerObj.options.zIndex = 1;
        }
      });

      this.createMap();
    }
  }

  showMapClick(): void {
    if (!this.showMap && !this.isMapRendered) {
      this.renderMap();
      this.isMapRendered = true;
    }
    this.showMap = !this.showMap;
  }

  /**
   * Create map and markers.
   * @memberof GoogleMapsComponent
   */
  createMap(): void {
    const mapDiv = this.gmap?.nativeElement as HTMLElement;
    this.map = new google.maps.Map(mapDiv, this.mapOptions);
    this.map.setCenter(this.position);
    this.createMarkers();
  }

  /**
   * Add markers on google map.
   * @memberof GoogleMapsComponent
   */
  createMarkers(): void {
    this.markers.forEach((m) => {
      const marker = new google.maps.Marker({ ...m.options });
      marker.setMap(this.map);
    });
  }

  displayMap(doShowMap: boolean): string {
    return doShowMap ? 'Hide Map' : 'Show Map'; 
  }
}
