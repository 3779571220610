import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Banner } from '../../models/banner';
import { OfficeAddress } from '../../models/office-address';
import { Provider, ProviderInterfaceHelper } from '../../models/provider';
import { ProviderImage } from '../../models/provider-image';
import { ResizeService } from '../../services/resize.service';
import { FadLinkService } from '../../services/fad-link.service';
import { OnlineBookingAvailability } from '../../models/online-booking-availability';
import { AppointmentType } from '../../models/appointment-type';
import { HierarchySecurityService } from '../../configs/HierarchySecurityService';
import { ConfigurationService } from '../../services/configuration.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LogoConstants } from 'src/app/constants/logo-constants';
import { ProviderVideo } from 'src/app/models/provider-video';
import { ApiConstants } from '../../constants/api-constants.new';
import { LogoDetails } from 'src/app/models/configuration';
@Component({
  selector: 'cs-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})
export class ResultCardComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() provider: Provider;
  @Input() showDocAsap = false;
  @Input() docAsapIds: { npi: number; docAsapId: number }[] = [];
  @Input() preferredProviderSelect = false;

  @ViewChild('resultCard') resultCard: ElementRef;

  @Output() scheduleProvider: EventEmitter<Provider> = new EventEmitter<Provider>();
  @Output() preferredProvider: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleModal: EventEmitter<string> = new EventEmitter<string>();

  hideExtraContent = true;
  isProviderCard: boolean;
  banner: Banner = new Banner();
  docAsapId?: string;
  showProviderLogos: boolean;
  logoUrls: LogoDetails[] = [];
  ratingTooltipText: string;
  schedulingType: string;
  providerSchedulingFeature: boolean;
  showMedGroupBanner: boolean;
  showRatingText: boolean;
  showNumReviews: boolean;
  showStarRatingsBanner: boolean;
  showStarRatings: boolean;
  providerSpecialities: string[];
  useNewProviderDetailsRoute: boolean;
  showAffiliations: boolean;
  srcUrl: SafeResourceUrl;
  divisionCodes: string[];
  showProviderVideoLinkMarketConfig: boolean;
  showProviderVideoLink: boolean;
  forceDisableBookOnline = false;
  showImprovedUX: boolean;

  nonSEOSecondarySpecialities: string[];
  videoId: string;
  stars = [1,2,3,4,5];
  olsDepartmentId?: string;
  olsProviderId?: string;
  olsBlockIt?: string;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public resizeService: ResizeService,
    public fadUrl: FadLinkService,
    private securityService: HierarchySecurityService,
    private configurationService: ConfigurationService,
    public sanitizer: DomSanitizer,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showImprovedUX = this.configurationService.showImprovedUX();
    this.showStarRatings =
      this.securityService.shouldShowStarRatings(this.provider) ?? this.configurationService.defaultProviderRatings.defaultShowStarRatings;
    this.getStarRatingBanner();
    this.showMedGroupBanner = this.configurationService.showMedGroupBanner();
    this.schedulingType = this.configurationService.getOnlineSchedulingType();
    this.providerSchedulingFeature = this.configurationService.getProviderSchedulingFeature();
    const marketCodes = this.configurationService?.getMarketCodes().length > 0 ? this.configurationService?.getMarketCodes()[0] : null;
    const divisionCodes = this.configurationService?.getDivisionCodes().length > 0 ? this.configurationService.getDivisionCodes()[0] : null;
    const externalSystems = this.provider?.externalSystems?.length > 0 &&  
    (typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined' 
    && typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsProviderId) != 'undefined') ? true : false;

    const externalSystemsDH = this.provider?.externalSystems?.length > 0
      &&
      (typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined') ? true : false;

    if (this.schedulingType === "ThirdParty" 
    && this.providerSchedulingFeature 
    && externalSystems
    && marketCodes?.toLowerCase() == "kentucky" && this.provider?.isBookOnline) {
    this.initOlsKY();
    }
    else if (this.schedulingType === 'ThirdParty'
      && externalSystemsDH
      && divisionCodes?.toLowerCase() == "dignity-health"      
      && Boolean(typeof this.provider?.onlineBookingAvailability[0]?.appointmentTypes?.find(({ name }) => name.toLowerCase() == 'online scheduling for new patients' || 'online scheduling for all visits' || 'online scheduling for video visits') !== 'undefined')) {
      this.initOlsDHForBlockIt();
      }
    else {
    this.initDocAsap();
    }
    this.showProviderMedGroupLogos();
    this.setProviderMedGroupLogos();
    this.updateProviderFullName();
    this.providerSpecialities = ProviderInterfaceHelper.getProviderTopSpecialities(this.provider);
    this.nonSEOSecondarySpecialities = ProviderInterfaceHelper.getProviderNonSEOSpecialities(this.provider, this.providerSpecialities);
    this.ratingTooltipText =
      this.securityService.getRatingsTooltipText(this.provider) ??
      this.configurationService.defaultProviderRatings.defaultRatingsTooltipText;
    this.showRatingText = this.securityService.shouldShowRatingText(this.provider);
    this.showNumReviews = this.securityService.shouldShowReviewCount(this.provider);
    this.useNewProviderDetailsRoute = this.configurationService.UseNewProviderDetailsRoute();
    this.showAffiliations = this.configurationService.showHospitalAffiliations();
    this.divisionCodes = this.configurationService.getDivisionCodes();
    this.showProviderVideoLinkMarketConfig = this.configurationService.showProviderVideoLink();
    this.setShowProviderVideoLink();
    if (!this.configurationService.useLegacyAPI() && this.configurationService.getOnlineSchedulingType() === 'none') {
      this.forceDisableBookOnline = true;
    }
    this.provider.loadingRating = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    this.showStarRatings =
      this.securityService.shouldShowStarRatings(this.provider) ?? this.configurationService.defaultProviderRatings.defaultShowStarRatings;
    this.getStarRatingBanner();
    const providerChanges = changes?.provider;
    const preferredChanges = changes?.preferredProviderSelect;

    if (preferredChanges !== undefined && preferredChanges.currentValue && typeof preferredChanges.currentValue === 'string') {
      this.preferredProviderSelect = preferredChanges.currentValue === 'true';
    }

    if (providerChanges !== undefined && providerChanges.currentValue && providerChanges.currentValue.toString().startsWith('{')) {
      try {
        this.provider = JSON.parse(providerChanges.currentValue);
      } catch (Exception) {
        if (console) {
          console.error('Could not parse Json. Check for well formed Json.');
        }
      }
    }
  }

  ngAfterViewInit() {
    this.isProviderCard = !!this.resultCard.nativeElement.parentNode && this.resultCard.nativeElement.parentNode.clientWidth > 0;
    this.cdRef.detectChanges();
  }

  initOlsKY() {
    this.showDocAsap = true;
    this.olsDepartmentId = typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined' ? this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId).systemId : null;
    this.olsProviderId = typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsProviderId) !== 'undefined' ? this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsProviderId).systemId : null;
    if (this.olsDepartmentId && this.olsProviderId)
    {
      this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.configurationService.getRuntimeConfiguration().myChartUrl}&dept=${this.olsDepartmentId.replace(/\s+/g, ' ').trim()}&id=${this.olsProviderId.replace(/\s+/g, ' ').trim()}`
      );
    }
  }

  initOlsDHForBlockIt(): void {
    this.showDocAsap = true;
    this.olsBlockIt = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt).systemId : null;
    if (this.olsBlockIt) {
      this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.configurationService.getRuntimeConfiguration().blockItUrl}${this.olsBlockIt}`
      );
    }
  }

  initDocAsap() {
    if (this.schedulingType === 'ThirdParty') {
      this.showDocAsap = true;
      if (this.providerSchedulingFeature) {
        this.docAsapId = this.provider?.isBookOnline ? this.provider?.providerSchedulingId ?? null : null;
      } else {
        const matchingProvider = this.docAsapIds.find((provider: { npi: number; docAsapId: number }) => {
          return this.provider.npi.toString() === provider.npi.toString();
        });

        if (matchingProvider) {
          this.docAsapId = matchingProvider.docAsapId.toString();
        } else {
          this.docAsapId = null;
        }
      }
      if (this.docAsapId && this.provider.ehrCode) {
        this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://docasap.com/white-label/specialty_id/0/key_reason/-1/key_doc_id/${this.docAsapId}/key_level/3/key_type/INLINE/key_partner_code/${this.provider.ehrCode}/key_mobile_inline_button/0/iframeWidth/550/iframeHeight/300/key_lazy_loading/0`
        );
      }
    } else {
      return;
    }
  }

  get isMobileViewport() {
    return this.resizeService.isMobileViewport || this.isProviderCard;
  }

  get providerOffices(): OfficeAddress[] {
    return ProviderInterfaceHelper.getProviderOfficesSortedByDistanceFromSearch(this.provider);
  }

  get providerImage() {
    const providerImagesCopy = this.provider.images?.slice();
    providerImagesCopy.sort((x: ProviderImage, y: ProviderImage) => y.width * y.height - x.width * x.height);
    const imagesBySizeDecreasing =
      providerImagesCopy?.length > 0
        ? providerImagesCopy.filter((image: ProviderImage) => {
            return image.height === 160 && image.width === 120;
          })
        : [];

    const placeholderImage: ProviderImage = {
      url:
        this.provider.gender?.toLowerCase() === 'male'
          ? this.configurationService.malePlaceholder
          : this.configurationService.femalePlaceholder
    };

    const providerImage = imagesBySizeDecreasing[0] ?? placeholderImage;
    providerImage.url = providerImage.url?.replace(/^http:\/\//i, 'https://');

    return providerImage.url;
  }

  get providerTarget() {
    if (this.fadUrl.isFadSpa) {
      return '_self';
    } else {
      return '_blank';
    }
  }

  goToProvider(scrollToComments = false) {
    if (scrollToComments) {
      sessionStorage.setItem('scrollToComments', 'true');
    } else {
      sessionStorage.removeItem('scrollToComments');
    }

    if (this.fadUrl.isFadSpa) {
      sessionStorage.setItem('searchResultsOrigin', 'true');
      this.router.navigateByUrl(this.providerLink).then();
    } else {
      window.open(this.providerLink, '_blank');
    }
  }

  get providerLink() {
    if (this.useNewProviderDetailsRoute && this.provider.primarySpecialty) {
      const urlValue = `/${
        this.provider.primarySpecialty && this.provider.primarySpecialty.makeURLFriendly()
      }/${this.provider.firstName?.makeURLFriendly()}-${this.provider.lastName?.makeURLFriendly()}-${this.provider.npi}`;
      return this.fadUrl.createUrlWithBase(urlValue);
    }
    const url = `/${this.provider.npi}-${this.provider.firstName?.makeURLFriendly()}-${this.provider.lastName?.makeURLFriendly()}`;
    return this.fadUrl.createUrlWithBase(url);
  }

  launchGuidedFlow() {
    this.scheduleProvider.emit(this.provider);
  }

  makeAsPreferredProvider() {
    this.preferredProvider.emit(this.provider.npi.toString());
  }

  getAltImageSrc(event: any) {
    event.target.src =
      this.provider.gender?.toLowerCase() === 'male'
        ? this.configurationService.malePlaceholder
        : this.provider.gender?.toLowerCase() === 'female'
        ? this.configurationService.femalePlaceholder
        : this.configurationService.blankPlaceholder;
  }

  get hasProviderOfficePhone(): boolean {
    return ProviderInterfaceHelper.hasProviderOfficePhone(this.provider);
  }

  get showCallToScheduleCTA(): boolean {
    const urgentCareRoles = this.configurationService.getProviderRolesForUrgentCare();
    let urgentCareProvider = false;
    if (this.provider?.roleType) {
      urgentCareProvider = urgentCareRoles.includes(this.provider.roleType);
    }
    if (!(this.showGuidedFlowCTA || this.showDocAsapCTA)) {
      if (this.providerOffices[0]?.phones && this.providerOffices[0]?.phones[0]) {
        return !urgentCareProvider;
      }
    }
    return false;
  }

  get showGuidedFlowCTA(): boolean {
    if (this.schedulingType === 'GuidedFlow') {
      const hasBookingLocation = (this.provider.onlineBookingAvailability || []).find((location: OnlineBookingAvailability) => {
        return location.supportsOnlineBooking;
      });

      const hasMappedApptType = (this.provider.onlineBookingAvailability || []).find((location: OnlineBookingAvailability) => {
        return (location.appointmentTypes || []).find((apptType: AppointmentType) => {
          return !!apptType.patientAppointmentType;
        });
      });

      const hasPhoneNumber = this.providerOffices[0]?.phones && this.providerOffices[0]?.phones[0];
      if (this.provider.isBookOnline && hasBookingLocation && hasMappedApptType && hasPhoneNumber) 
      {
        return true;
      }
    }
    return false;
  }

  get showPatientAgeRestrictions(): boolean {
    return this.configurationService.showPatientAgeRestrictions() && Boolean(this.provider?.patientAgeGroups);
  }

  get buttonLabelText(): string {
    return `Schedule an appointment with ${this.provider.displayFullName} ${this.showPatientAgeRestrictions ? 'and accepting appointments from patients '+ this.provider.patientAgeGroups +' years of age' : '' }`
  }

  get showDocAsapCTA(): boolean {
   return (this.schedulingType === 'ThirdParty' && Boolean(this.docAsapId) && Boolean(this.provider?.ehrCode)) ||
   (this.schedulingType === 'ThirdParty' && 
   Boolean(this.olsDepartmentId) && 
       Boolean(this.olsProviderId)) || (this.schedulingType === 'ThirdParty' &&
         Boolean(this.olsBlockIt));
  }

  get showTemporarilyUnavailableCTA(): boolean {
    return (
      this.showCallToScheduleCTA && this.provider.isBookOnline && !this.forceDisableBookOnline && this.configurationService.useLegacyAPI()
    );
  }

  public showProviderMedGroupLogos() {
    this.showProviderLogos = this.securityService.shouldShowLogos(this.provider);
  }

  public setShowProviderVideoLink() {
    this.showProviderVideoLink = this.provider.videos?.length > 0 && this.configurationService.showProviderVideoLink();
    if (this.showProviderVideoLink) {
      this.provider.videos?.forEach((video: ProviderVideo) => {
        if (!this.videoId) {
          if(video.url.trim() != '') {
            this.videoId = video?.url?.split('?v=')[1]?.substring(0, 11);
            this.showProviderVideoLink = true;
          } else {
            this.showProviderVideoLink = false;
          }
        }
      });
    }
  }
  public setProviderMedGroupLogos() {
    if (this.showProviderLogos) {
      if (
        !window.location.host.toLowerCase().includes(LogoConstants.fad) &&
        !window.location.host.toLowerCase().includes(LogoConstants.mycareteam) &&
        !window.location.host.toLowerCase().includes(LogoConstants.chart) &&
        !this.fadUrl.isSelfHosted()
      ) {
        this.logoUrls = ProviderInterfaceHelper.getProviderLogoDetailsAEM(this.provider, this.configurationService.getMedGroupCodes());
      } else {
        this.logoUrls = ProviderInterfaceHelper.getProviderLogoDetailsFAD(this.provider, this.configurationService.getMedGroupCodes());
        this.buildFullyQualifiedURLs();
      }
    }
  }

  buildFullyQualifiedURLs(): void {
    const fadBaseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;
    const domain = new URL(fadBaseUrl);
    this.logoUrls.forEach((x) => x.url = domain.origin + x.url);
  }

  updateProviderFullName(): void {
    if (!this.configurationService.showProviderPrefix()) {
      this.provider.displayFullName = ProviderInterfaceHelper.removeProviderNamePrefix(this.provider.firstName, this.provider.lastName, this.provider.degree);
    }
  }
  
  displayVideo(): void {
    this.toggleModal.emit('https://www.youtube.com/embed/' + this.videoId);
  }

  providerDisplayNameInformation(providerName: string): string {
    return providerName + " image icon";
  }

  getStarRatingBanner() {
    const showRatingFromQueryString = this.route.snapshot.queryParamMap.get('showRating');
    const isAcceptQueryString = this.configurationService.acceptQueryString();
    // set the session
    if (showRatingFromQueryString) {
      sessionStorage.setItem('showRating', showRatingFromQueryString);
    }
    const hasShowRatingEnabled = sessionStorage.getItem('showRating');
    if (hasShowRatingEnabled && isAcceptQueryString != undefined) {
      if (hasShowRatingEnabled === 'true' && isAcceptQueryString) {
        this.showRatingBanner();
      }
    }
    else if (isAcceptQueryString == undefined) {
      this.showRatingBanner();
    }
  }
  
  showRatingBanner() {
    this.showStarRatingsBanner =
      this.securityService.shouldShowStarRatingsBanner(this.provider) ??
      this.configurationService.defaultProviderRatings.defaultShowStarRatingBanner;
  }
}
