import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { providerDetailConstants } from '../../constants/provider-detail-constants';

@Component({
  selector: 'cs-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss']
})
export class ExpandableListComponent implements AfterViewInit {
  @Input() sectionTitle = '';
  @Input() listItems = [];
  @Input() isInsuranceSection = false;
  @Input() useColumns = true;
  @Input() isAffiliationSection = false;
  @Input() isCredentialsSection = false;
  @Input() isAwardsSection = false;
  hideExtraContent = true;
  moreLessExpanded = false;
  listCustomClass: string;
  showMoreLessButton: boolean;

  insuranceCarriersDisclaimerText = providerDetailConstants.InsuranceCarriersDisclaimer;

  @ViewChild('expandableList') expandableList: ElementRef;
  @ViewChild('listContainer') listContainer: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    const setHeight =
      parseInt(window.getComputedStyle(this.expandableList.nativeElement).lineHeight, 10) * (this.isAffiliationSection ? 3 : 6) +
      (this.isCredentialsSection ? 6 * 8 : 0);
    const containerHeight = parseInt(this.listContainer.nativeElement.scrollHeight, 10);
    const shouldHide = containerHeight > setHeight;

    this.hideExtraContent = shouldHide && !this.moreLessExpanded;
    this.showMoreLessButton = shouldHide;
    this.listContainer.nativeElement.style.height = this.hideExtraContent ? `${setHeight}px` : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const setHeight =
        parseInt(window.getComputedStyle(this.expandableList.nativeElement).lineHeight, 10) * (this.isAffiliationSection ? 3 : 6) +
        (this.isCredentialsSection ? 6 * 8 : 0); // 8 is credential section li padding bottom
      const containerHeight = parseInt(this.listContainer.nativeElement.scrollHeight, 10);
      const shouldHide = containerHeight > setHeight;

      this.hideExtraContent = shouldHide && !this.moreLessExpanded;
      this.showMoreLessButton = shouldHide;
      this.listContainer.nativeElement.style.height = this.hideExtraContent ? `${setHeight}px` : '';
      this.listCustomClass = this.sectionTitle.toLowerCase().replace(/ /g, '-');
    });
  }

  toggleListExpanded() {
    this.hideExtraContent = !this.hideExtraContent;
    this.moreLessExpanded = !this.moreLessExpanded;

    const setHeight =
      parseInt(window.getComputedStyle(this.expandableList.nativeElement).lineHeight, 10) * (this.isAffiliationSection ? 3 : 6) +
      (this.isCredentialsSection ? 6 * 8 : 0);
    this.listContainer.nativeElement.style.height = this.hideExtraContent ? `${setHeight}px` : '';
  }
}
