import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { isNumeric } from 'rxjs/internal-compatibility';
import { catchError } from 'rxjs/operators';
import { ApiConstants } from 'src/app/constants/api-constants.legacy';
import { AvailabilitySelect } from 'src/app/models/availability-select.enum';
import { Facet } from 'src/app/models/facet';
import { FiltersModel, FiltersModelPartialHelper, FacetSummary } from 'src/app/models/filters';
import { SelectedLocation } from 'src/app/models/location';
import { MedGroup } from 'src/app/models/medgroup';
import { SearchFacet } from 'src/app/models/search-facet';
import { SearchFilter } from 'src/app/models/search-filter';
import { ApiResponse, ApiResponseCreator } from 'src/app/utils/api-response';
import { RuntimeConfiguration } from '../configuration-injection.service';
import { ConfigurationService } from '../configuration.service';
import { FilterService, ProviderSearchResponse } from '../filter.service';
import { ProviderService } from '../provider.service';

@Injectable({
  providedIn: 'root'
})
export class FilterLegacyService extends FilterService {
  public showGuidedFlow = false;
  public runtimeConfig: RuntimeConfiguration;
  public apiConstants: { [key: string]: string };
  public apiBaseUrl: string;
  private useLegacyAPI = false;
  constructor(
    protected providerService: ProviderService,
    protected configurationService: ConfigurationService,
    protected route: ActivatedRoute
  ) {
    super();
    this.showGuidedFlow = this.configurationService.getOnlineSchedulingType() === 'GuidedFlow';
    this.runtimeConfig = configurationService.getRuntimeConfiguration();
    this.apiBaseUrl = this.runtimeConfig.apiBaseUrl;
    this.apiConstants = ApiConstants;
    this.useLegacyAPI = this.configurationService?.useLegacyAPI();
  }

  getFilterStates(queryParams: Params, filtersModel: FiltersModel, medGroupCode: string = null, searchstring: string = null): FiltersModel {
    const bookOnline = queryParams.bookonline ?? false;
    const allVisits = queryParams.allVisits ?? false;
    const acceptingNewPatients = queryParams.acceptingnew ?? false;
    let videoVisits = false;
    if (queryParams?.videovisits || searchstring?.trim().toLowerCase().replace(/\s/g, '').replace(/\W/g, '') === 'videovisits') {
      videoVisits = true;
    }
    const availability = !queryParams.availability
      ? null
      : queryParams.availability === '0'
        ? AvailabilitySelect.Today
        : queryParams.availability === '7'
          ? AvailabilitySelect.Next7Days
          : queryParams.availability === '14'
            ? AvailabilitySelect.Next14Days
            : queryParams.availability === '30'
              ? AvailabilitySelect.Next30Days
              : AvailabilitySelect.None;
    const specialties = !queryParams.specialties ? null : this.splitAndMapQueryString(queryParams.specialties);
    const affiliations = !queryParams.affiliations ? null : this.splitAndMapAffiliationQueryString(queryParams.affiliations);
    const gender = queryParams.gender;
    const insurances = !queryParams.insurances ? null : this.splitAndMapQueryString(queryParams.insurances);
    const languages = !queryParams.languages ? null : this.splitAndMapQueryString(queryParams.languages);
    let searchRadius: number = this.configurationService.getDefaultSearchDistance();

    filtersModel.onlineSchedulingToggle = filtersModel.onlineSchedulingApplied = bookOnline ?? filtersModel.onlineSchedulingApplied;
    filtersModel.onlineSchedulingAllVisitToggle = filtersModel.onlineSchedulingAllVisitApplied = allVisits ?? filtersModel.onlineSchedulingAllVisitApplied;
    filtersModel.newPatientsToggle = filtersModel.newPatientsApplied = acceptingNewPatients ?? filtersModel.newPatientsApplied;
    filtersModel.videoVisitsToggle = filtersModel.videoVisitsApplied = videoVisits ?? filtersModel.videoVisitsApplied;
    filtersModel.availabilitySelect = filtersModel.providerAvailability = availability ?? AvailabilitySelect.None;
    filtersModel.selectedSpecialties = filtersModel.providerSpecialties = specialties
      ? specialties
      : filtersModel.selectedSpecialties
        ? filtersModel.selectedSpecialties
        : [];
    filtersModel.selectedHospitals = filtersModel.providerHospitals = affiliations ?? [];
    filtersModel.availabilityTitle = availability ?? 'Availability';

    if (medGroupCode) {
      const selectedMedgroup = filtersModel.medicalGroupList.find((medGroup: any) => {
        return medGroup.value === medGroupCode;
      });
      const selectedMedgroupList: FacetSummary[] = [];
      selectedMedgroupList.push({ name: selectedMedgroup.label, count: 0, selected: true });
      filtersModel.selectedMedicalGroup = filtersModel.providerMedicalGroup = selectedMedgroupList;

      if (filtersModel.providerMedicalGroup) {
        searchRadius = this.configurationService.getDefaultSearchDistanceWhenMedGroupFilterApplied();
      }
    } else {
      filtersModel.selectedMedicalGroup = filtersModel.providerMedicalGroup = null;
      filtersModel.medicalGroupTitle = 'Medical group';
      if (
        filtersModel.providerSearchRadius ===
        FiltersModelPartialHelper.getRadiusKeyForDistance(this.configurationService.getDefaultSearchDistanceWhenMedGroupFilterApplied())
      ) {
        filtersModel.providerSearchRadius = filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(
          this.configurationService.getDefaultSearchDistance()
        );
      }
    }

    if (isNumeric(queryParams.distance) && FiltersModelPartialHelper.hasRadiusKeyForDistance(+queryParams.distance)) {
      searchRadius = +queryParams.distance;
    }

    filtersModel.genderSelect = filtersModel.providerGender = gender ?? '';
    filtersModel.selectedInsurances = filtersModel.providerInsurances = insurances ?? [];
    filtersModel.selectedLanguages = filtersModel.providerLanguages = languages ?? [];
    FiltersModelPartialHelper.setRadiusKeyForDistance(filtersModel, searchRadius);
    return filtersModel;
  }

  createQueryParams(filtersModel: Partial<FiltersModel>): Params | null | undefined {
    let queryParams: Params = {};

    queryParams = this.checkForToggleSpecialtiesHospitals(queryParams, filtersModel);
    queryParams = this.checkForAvailability(queryParams, filtersModel);
    queryParams = this.checkForGenderInsuranceAndLanguages(queryParams, filtersModel);
    queryParams = this.checkForLocationAndDistanceParams(queryParams, filtersModel);
    queryParams = this.checkForProviderRoleType(queryParams, filtersModel);
    queryParams = this.checkForSortBy(queryParams, filtersModel);

    return queryParams;
  }

  private checkForSortBy(queryParams: Params, filtersModel: Partial<FiltersModel>): Params {
    if (filtersModel.sortBy) {
      queryParams.sortby = filtersModel.sortBy;
    }
    return queryParams;
  }

  private checkForGenderInsuranceAndLanguages(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    if (filtersModel.providerGender) {
      queryParams.gender = filtersModel.providerGender;
    }
    if (filtersModel.providerInsurances && filtersModel.providerInsurances.length > 0) {
      queryParams.insurances = filtersModel.providerInsurances.map((x) => x.name).join('|');
    }
    if (filtersModel.providerLanguages && filtersModel.providerLanguages.length > 0) {
      queryParams.languages = filtersModel.providerLanguages.map((x) => x.name).join('|');
    }

    return queryParams;
  }

  private checkForToggleSpecialtiesHospitals(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    if (filtersModel.onlineSchedulingApplied) {
      queryParams.bookonline = filtersModel.onlineSchedulingApplied;
    }
    if (filtersModel.videoVisitsApplied) {
      queryParams.videovisits = filtersModel.videoVisitsApplied;
    }
    if (filtersModel.newPatientsApplied) {
      queryParams.acceptingnew = filtersModel.newPatientsApplied;
    }
    if (filtersModel.providerSpecialties && filtersModel.providerSpecialties.length > 0) {
      queryParams.specialties = filtersModel.providerSpecialties.map((x) => x.name).join('|');
    }
    if (filtersModel.providerHospitals && filtersModel.providerHospitals.length > 0) {
      queryParams.affiliations = filtersModel.providerHospitals.map((x) => x.name.replace("'", "'")).join('|');
    }
    if (filtersModel.onlineSchedulingAllVisitApplied) {
      queryParams.allVisits = filtersModel.onlineSchedulingAllVisitApplied;
    }

    return queryParams;
  }

  private checkForAvailability(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    if (filtersModel.providerAvailability) {
      queryParams.availability =
        filtersModel.providerAvailability === AvailabilitySelect.Today
          ? 0
          : filtersModel.providerAvailability === AvailabilitySelect.Next7Days
            ? 7
            : filtersModel.providerAvailability === AvailabilitySelect.Next14Days
              ? 14
              : filtersModel.providerAvailability === AvailabilitySelect.Next30Days
                ? 30
                : null;
    }

    return queryParams;
  }
  private checkForLocationAndDistanceParams(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    const selectedDistance = FiltersModelPartialHelper.getDistanceForSearchRadiusKey(filtersModel);
    if (
      filtersModel.providerMedicalGroup.length > 0 &&
      selectedDistance !== this.configurationService.getDefaultSearchDistanceWhenMedGroupFilterApplied()
    ) {
      queryParams.distance = selectedDistance;
    } else if (filtersModel.providerMedicalGroup.length == 0 && selectedDistance !== this.configurationService.getDefaultSearchDistance()) {
      queryParams.distance = selectedDistance;
    }
    if (this.route.snapshot.queryParamMap.has('location')) {
      queryParams.location = this.route.snapshot.queryParamMap.get('location');
    }
    return queryParams;
  }

  // Adding Provider role types dynamic to queryParams
  private checkForProviderRoleType(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    queryParams = this.checkForMedicalGroupSelection(queryParams, filtersModel);
    queryParams = this.checkForAutoSelectionCheckBoxSpecialties(queryParams, filtersModel);
    queryParams = this.checkForOtherConditions(queryParams, filtersModel);

    return queryParams;
  }

  // Medical Group Provider role Check box validation for auto selection
  checkForMedicalGroupSelection(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    let needToIncludeRoleType = false;
    if (filtersModel.providerMedicalGroup?.length > 0) {
      const medicalGroupList = FiltersModelPartialHelper.getMedGroups(this.configurationService.getMedGroupCodes());
      for (const providerMedicalGroup of filtersModel.providerMedicalGroup) {
        const currentGroup = medicalGroupList.filter((s) => s.label === providerMedicalGroup.name)[0];
        if (currentGroup?.enableProviderRoleTypes) {
          needToIncludeRoleType = true;
        }
      }

      if (needToIncludeRoleType) {
        if (sessionStorage.getItem('manualSelection') === 'false') {
          delete queryParams.includeProviderRoleTypes;
        } else {
          queryParams.includeProviderRoleTypes = true;
          filtersModel.includeProviderRoleType = true;
        }
      } else {
        delete queryParams.includeProviderRoleTypes;
      }
    }

    return queryParams;
  }

  // Specialties Provider role Check box validation for auto selection
  checkForAutoSelectionCheckBoxSpecialties(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    if (
      this.configurationService.getAutoCheckBoxSelectionSpecalities() &&
      filtersModel.providerSpecialties &&
      filtersModel.providerSpecialties.length > 0
    ) {
      const findAutoSelectSpecialties = filtersModel.providerSpecialties.filter((val) => {
        return this.configurationService.getAutoCheckBoxSelectionSpecalities().includes(val.name);
      });

      if (
        findAutoSelectSpecialties.length > 0 ||
        (findAutoSelectSpecialties.length <= 0 && sessionStorage.getItem('manualSelection') === 'true')
      ) {
        queryParams.includeProviderRoleTypes = true;
      }
    }

    if (
      !this.configurationService
        .getAutoCheckBoxSelectionSpecalities()
        .includes(filtersModel.specialtyFilterText?.replace('-', ' ').toTitleCase()) &&
      sessionStorage.getItem('prevSpecialityisAutoTgrChkbx') === 'true'
    ) {
      delete queryParams.includeProviderRoleTypes;
      sessionStorage.removeItem('prevSpecialityisAutoTgrChkbx');
    }

    return queryParams;
  }

  // Provider role Check box validation for all other Auto selection condition
  checkForOtherConditions(queryParams: Params, filtersModel: Partial<FiltersModel>): Params | null | undefined {
    if (
      (!filtersModel.providerMedicalGroup || filtersModel.providerMedicalGroup.length === 0) &&
      (!filtersModel.providerSpecialties || filtersModel.providerSpecialties.length === 0)
    ) {
      if (sessionStorage.getItem('manualSelection') === 'true') {
        queryParams.includeProviderRoleTypes = true;
      } else {
        delete queryParams.includeProviderRoleTypes;
      }
    }

    return queryParams;
  }

  protected getFacetCount(searchFilters: SearchFilter, facetType: string, facetValue: string): number {
    return (
      searchFilters.facets
        .find((facet: SearchFacet) => facet.type.toLowerCase() === facetType.toLowerCase())
        ?.values.find((val: Facet) => val.value.toLowerCase() === facetValue.toLowerCase())?.count ?? 0
    );
  }

  protected getFacetValues(searchFilters: SearchFilter, facetType: string): FacetSummary[] {
    return (
      searchFilters.facets
        .find((facet: SearchFacet) => facet.type === facetType)
        ?.values.map((val: Facet) => {
          return {
            name: val.value,
            count: val.count,
            selected: val.selected
          } as FacetSummary;
        }) ?? []
    );
  }

  getFacetInfo(searchFilters: SearchFilter, filtersModel: FiltersModel): FiltersModel {
    filtersModel.searchDone = true;

    const divisionCodes =  this.configurationService.getDivisionCodes();
    if (divisionCodes.length > 0 && divisionCodes[0].toString() === 'dignity-health' && this.configurationService.getMarketCodes()[0] === undefined) {
      this.getNewAppointmentTypes(searchFilters, filtersModel);
    }
    else
    {
      filtersModel.onlineSchedulingProvidersCount = !this.useLegacyAPI
      ? this.getFacetCount(searchFilters, 'bookOnlineAvailable', 'true')
      : this.getFacetCount(searchFilters, 'bookOnline', 'True');
    filtersModel.videoVisitsProvidersCount = this.getFacetCount(searchFilters, 'videoCalls', 'True');    
    }
    
    filtersModel.newPatientsProvidersCount = this.getFacetCount(searchFilters, 'patients', 'True');
    filtersModel.todayCount = this.getFacetCount(searchFilters, 'appointmentDatesBuckets', AvailabilitySelect.Today);
    filtersModel.next7Count = this.getFacetCount(searchFilters, 'appointmentDatesBuckets', AvailabilitySelect.Next7Days);
    filtersModel.next14Count = this.getFacetCount(searchFilters, 'appointmentDatesBuckets', AvailabilitySelect.Next14Days);
    filtersModel.next30Count = this.getFacetCount(searchFilters, 'appointmentDatesBuckets', '15 days and later');

    

    filtersModel.availableSpecialties = this.getFacetValues(searchFilters, 'specialties');
    filtersModel.availableSpecialties.sort((prev, next) => prev.name.localeCompare(next.name));
    if (filtersModel.availableSpecialties.length > 0) {
      filtersModel.selectedSpecialties = filtersModel.providerSpecialties = filtersModel.availableSpecialties.filter(
        (facet: FacetSummary) => facet.selected
      );
    }

    filtersModel.specialtyFilterText =
      filtersModel.providerSpecialties.length > 1
        ? `${filtersModel.providerSpecialties.length} specialties`
        : filtersModel.providerSpecialties[0]?.name ?? 'Specialty';

    filtersModel.availableHospitals = this.getFacetValues(searchFilters, 'hospitals');
    filtersModel.availableHospitals.sort((prev, next) => prev.name.localeCompare(next.name));
    if (filtersModel.availableHospitals.length > 0) {
      filtersModel.selectedHospitals = filtersModel.providerHospitals = filtersModel.availableHospitals.filter(
        (facet: FacetSummary) => facet.selected
      );
    }

    filtersModel.hospitalFilterText =
      filtersModel.providerHospitals.length > 1
        ? `${filtersModel.providerHospitals.length} hospital affiliations`
        : filtersModel.providerHospitals[0]?.name ?? 'Hospital Affiliation';

    filtersModel.femaleCount = this.getFacetCount(searchFilters, 'genders', 'F');
    filtersModel.maleCount = this.getFacetCount(searchFilters, 'genders', 'M');
    filtersModel.nonbinaryCount = this.getFacetCount(searchFilters, 'genders', 'N');
    filtersModel.otherCount = this.getFacetCount(searchFilters, 'genders', 'O');

    filtersModel.availableInsurances = this.getFacetValues(searchFilters, 'insurances');
    filtersModel.selectedInsurances = filtersModel.providerInsurances = filtersModel.availableInsurances.filter(
      (facet: FacetSummary) => facet.selected
    );

    filtersModel.availableLanguages = this.getFacetValues(searchFilters, 'languages');
    filtersModel.availableLanguages.sort((prev, next) => prev.name.localeCompare(next.name));
    filtersModel.selectedLanguages = filtersModel.providerLanguages = filtersModel.availableLanguages.filter(
      (facet: FacetSummary) => facet.selected
    );

    filtersModel.insuranceFilterText =
      filtersModel.providerInsurances.length > 1
        ? `${filtersModel.providerInsurances.length} insurances`
        : filtersModel.providerInsurances[0]?.name ?? 'Insurances accepted';


    return JSON.parse(JSON.stringify(filtersModel));
  }

  getNewAppointmentTypes(searchFilters, filtersModel) {
    const appointmentTypes = this.getFacetValues(searchFilters, 'appointmentTypes');
    if (appointmentTypes != null && appointmentTypes.length > 0) {
      appointmentTypes.forEach(e => {
        if (e.name == 'online scheduling for all visits') {
          filtersModel.onlineSchedulingAllVisitProvidersCount = e.count;
        }
        if (e.name == 'online scheduling for new patients') {
          filtersModel.onlineSchedulingProvidersCount = e.count;
        }
        if (e.name == 'online scheduling for video visits') {
          filtersModel.videoVisitsProvidersCount = e.count;
        }
      });
    }
    return filtersModel;
  }

  getSortingValue(sortParam: string): string {
    let sortName = '';
    switch (sortParam) {
      case 'lastName-asc': {
        sortName = 'LastName';
        break;
      }
      case 'lastName-desc': {
        sortName = 'LastNameInverted';
        break;
      }
      default: {
        sortName = sortParam;
        break;
      }
    }
    return sortName.toLowerCase();
  }

  convertFilterModelToQueryString(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    searchFrom: string,
    searchFilters: SearchFilter = null,
    pageNumber = 1,
    includeProviderRole?: boolean
  ): [string, HttpParams] {
    let httpParams = new HttpParams();
    httpParams = httpParams.setSanitizedKey(this.apiConstants.addPagesinUrl, pageNumber);

    const query = `${this.apiBaseUrl}${this.apiConstants.providerSearchApi}`;
    if (searchTerm && searchTerm.toLowerCase().indexOf('video') < 0) {
      httpParams = httpParams.set('query', searchTerm);
    }

    httpParams = httpParams.setKeyValue(this.apiConstants.addIncludeOptionsinUrlForGuidedFlow);

    if (!this.showGuidedFlow) {
      httpParams = httpParams.setKeyValue(this.apiConstants.addMaxAppointmentsinUrl);
    }

    httpParams = httpParams.setKeyValue(this.apiConstants.addFacetsinUrl);
    httpParams = httpParams.setKeyValue(this.apiConstants.addsearchModeinUrl);

    httpParams = this.setSortByParams(httpParams, searchFilters);

    httpParams = this.filterModelParam(httpParams, filtersModel, locationInfo);

    httpParams = this.setLocationParam(httpParams, locationInfo);

    if (includeProviderRole) {
      httpParams = httpParams.setKeyValue(this.apiConstants.addProviderRoleTypes);
    }
    return [query, httpParams];
  }

  setLocationParam(httpParams: HttpParams, locationInfo: SelectedLocation): HttpParams {
    if (locationInfo && (locationInfo.name || locationInfo.city || locationInfo.state || locationInfo.zip || locationInfo.latitude || locationInfo.longitude)) {
      httpParams = httpParams.setNonNull('name', locationInfo.name);
      httpParams = httpParams.setNonNull('city', locationInfo.city);
      httpParams = httpParams.setNonNull('state', locationInfo.state);
      if (locationInfo.zip) {
        httpParams = httpParams.setNonNull('zip', locationInfo.zip);
      }
      httpParams = httpParams.setNonNull('latitude', locationInfo.latitude);
      httpParams = httpParams.setNonNull('longitude', locationInfo.longitude);
    }
    return httpParams
  }

  setSortByParams(httpParams: HttpParams, searchFilters: SearchFilter): HttpParams {
    if (searchFilters?.sortBy) {
      if (!isNumeric(searchFilters.sortBy)) {
        const sortingOptions = this.configurationService.getSortingConfig();
        const sortName = this.getSortingValue(searchFilters.sortBy);
        const selectedOption = sortingOptions.options.filter((s) => s.name.toLowerCase() === sortName)[0];
        searchFilters.sortBy = selectedOption.value;
      }
      httpParams = httpParams.setNonString(
        'sortby',
        searchFilters.sortBy
      );
    } else if (this.route.snapshot.queryParamMap.get('sortby')) {
      const sortBy = this.route.snapshot.queryParamMap.get('sortby');
      const sortingOption = this.configurationService.getSortingOptionByQueryParam(sortBy);
      if (sortingOption) {
        httpParams = httpParams.setNonString(
          'sortby',
          sortingOption.value
        );
        httpParams = httpParams.setNonString('sortby', sortingOption.value);
      }
    }
    return httpParams;
  }

  filterModelParam(httpParams: HttpParams, filtersModel: FiltersModel, locationInfo: SelectedLocation): HttpParams {
    if (filtersModel) {
      filtersModel = this.mapMedgroupIfNeeded(filtersModel);

      let distanceMapping =
        FiltersModelPartialHelper.getDistanceForProviderSearchRadiusKey(filtersModel) ??
        this.configurationService.getDefaultSearchDistance();

      if (filtersModel.providerMedicalGroup) {
        distanceMapping =
          FiltersModelPartialHelper.getDistanceForProviderSearchRadiusKey(filtersModel) ??
          this.configurationService.getDefaultSearchDistanceWhenMedGroupFilterApplied();
      }

      if (this.configurationService.showSearchRadiusFilter() && locationInfo) {
        httpParams = httpParams.setSanitizedKey(this.apiConstants.addDistanceinUrl, distanceMapping);
      }

      if (filtersModel.onlineSchedulingApplied) {
        httpParams = httpParams.setNonString('bookOnline', true);
      }

      if (filtersModel.onlineSchedulingAllVisitApplied) {
        httpParams = httpParams.setNonString('allVisits', true);
      }

      if (filtersModel.providerMedicalGroup && filtersModel.providerMedicalGroup.length > 0) {
        if (filtersModel.providerMedicalGroup.length > 1) {
          httpParams = httpParams.set('medgroup', filtersModel.providerMedicalGroup.map((x) => x.name).join('|'));
        } else {
          httpParams = httpParams.set('medgroup', filtersModel.providerMedicalGroup[0].name);
        }
      }

      if (filtersModel.videoVisitsApplied) {
        httpParams = httpParams.set('videoCalls', 'True');
      }

      if (filtersModel.newPatientsApplied) {
        httpParams = httpParams.set('patients', 'True');
      }

      if (filtersModel.availabilitySelect !== undefined && filtersModel.availabilitySelect !== AvailabilitySelect.None) {
        if (filtersModel.availabilitySelect === AvailabilitySelect.Next30Days) {
          httpParams = httpParams.set('appointmentDatesBuckets', '15 days and later');
        } else {
          httpParams = httpParams.set('appointmentDatesBuckets', filtersModel.availabilitySelect);
        }
      }
      httpParams = this.providerFilterParam(httpParams, filtersModel);
    }
    return httpParams;
  }

  providerFilterParam(httpParams: HttpParams, filtersModel: FiltersModel): HttpParams {
    if (filtersModel.providerSpecialties?.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray(
        'specialties',
        filtersModel.providerSpecialties.map((x) => x.name)
      );
    }

    if (filtersModel.providerMedicalGroup?.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray(
        'medgroup',
        filtersModel.providerMedicalGroup.map((x) => x.name)
      );
    }

    if (filtersModel.providerHospitals?.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray(
        'facilities',
        filtersModel.providerHospitals.map((x) => x.name)
      );
    }

    if (filtersModel.providerInsurances?.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray(
        'insurances',
        filtersModel.providerInsurances.map((x) => x.name)
      );
    }

    if (filtersModel.providerLanguages?.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray(
        'languages',
        filtersModel.providerLanguages.map((x) => x.name)
      );
    }

    if (filtersModel.providerGender === 'female') {
      httpParams = httpParams.set('gender', 'F');
    }
    if (filtersModel.providerGender === 'male') {
      httpParams = httpParams.set('gender', 'M');
    }
    if (filtersModel.providerGender === 'non-binary') {
      httpParams = httpParams.set('gender', 'N');
    }
    if (filtersModel.providerGender === 'other') {
      httpParams = httpParams.set('gender', 'O');
    }
    return httpParams;
  }

  mapMedgroupIfNeeded(filtersModel: FiltersModel): FiltersModel {
    const medGroups = this.configurationService.getMedGroupCodes();

    if (filtersModel.providerMedicalGroup?.length > 0) {
      let isMappingNeeded = false;

      for (const pmg of filtersModel.providerMedicalGroup) {
        isMappingNeeded =
          medGroups.filter((group: MedGroup) => {
            return group.code === pmg.name;
          }).length === 0;

        if (isMappingNeeded) break;
      }

      if (isMappingNeeded) {
        for (const pmg of filtersModel.providerMedicalGroup) {
          const medgroupItem = medGroups.filter((group: MedGroup) => {
            return group.title === pmg.name;
          })[0];
          pmg.name = medgroupItem.code;
        }
      }
    }

    return filtersModel;
  }

  getTypedFilterSearchResults(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    searchFrom: string,
    searchFilters?: SearchFilter,
    pageNumber = 1,
    searchSessionId: string = null,
    includeProviderRole?: boolean
  ): Observable<ApiResponse<ProviderSearchResponse>> {
    const [url, tempParams] = this.convertFilterModelToQueryString(
      searchTerm,
      locationInfo,
      filtersModel,
      searchFrom,
      searchFilters,
      pageNumber,
      includeProviderRole
    );
    let params = tempParams;

    if (searchSessionId) {
      params = params.set('searchSessionId', searchSessionId);
    }

    return this.providerService.getTypedFilterSearchResults<ApiResponse<ProviderSearchResponse>>(url, params).pipe(
      catchError(() => {
        return ApiResponseCreator.createObservable<ProviderSearchResponse>(
          {
            providers: []
          } as ProviderSearchResponse,
          false
        );
      })
    );
  }

  getFilteredSearchResults(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    searchFrom: string,
    searchFilters: SearchFilter = null,
    pageNumber = 1
  ) {
    const [url, params] = this.convertFilterModelToQueryString(searchTerm, locationInfo, filtersModel, searchFrom, searchFilters, pageNumber);
    return this.providerService.getFilteredSearchResults(url, params);
  }

  splitAndMapQueryString(queryString: string): FacetSummary[] {
    return decodeURIComponent(queryString)
      .split('|')
      .map((name: string) => ({ name } as FacetSummary));
  }

  splitAndMapAffiliationQueryString(queryString: string): FacetSummary[] {
    return queryString.split('|').map(
      (name: string) =>
      ({
        name: name.replace('Baylor St. Lukes Medical Center - Houston, TX', "Baylor St. Luke's Medical Center - Houston, TX")
      } as FacetSummary)
    );
  }

  handleManualProviderRoleSelectionProcess(filtersModel: FiltersModel): void {
    const medicalGroupList = FiltersModelPartialHelper.getMedGroups(this.configurationService.getMedGroupCodes());
    filtersModel.selectedMedicalGroup?.forEach((medGroup) => {
      const isProviderRoleGroup = medicalGroupList.some(
        (medList) => medList.label === medGroup['label'] && medList.enableProviderRoleTypes
      );
      if (isProviderRoleGroup) {
        sessionStorage.removeItem('manualSelection');
      }
    });
  }
}
