import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-patient-age-restrictions',
  templateUrl: './patient-age-restrictions.component.html',
  styleUrls: ['./patient-age-restrictions.component.scss']
})
export class PatientAgeRestrictionsComponent {
  @Input() patientAgeGroups: string;
  @Input() showImprovedUX? : boolean
}
