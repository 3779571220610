<div class="age-restrictions" [class.age-restrictions-new]="showImprovedUX" *ngIf="patientAgeGroups != undefined">
  <p class="p2">
    <span [class.age-restrictions-text]="showImprovedUX"> Accepting appointments from patients {{ patientAgeGroups }} years of age </span>
  </p>
</div>

<div class="age-restrictions-dh" [class.age-restrictions-new]="isProviderSearchResultPage" *ngIf="showAgeRestrictionRuleForDH">
  <p class="p2">
    <span> {{ageRestrictionRule}} </span>
  </p>
</div>
